<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <AMonthPicker
                    v-model:value="state.params.date"
                    :allow-clear="false"
                    placeholder="Pilih Bulan"
                    style="width:100%;"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterATSalesmanKabupatenByToko
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_TSO])"
                :xl="6" :md="8" :sm="12">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"
                    v-model:kabupaten="state.params.kabupaten"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_DISTRIBUTOR])"
                :xl="6" :md="8" :sm="12">
                <FilterUsers
                    style="width:100%;"
                    placeholder="Pilih User TSO"
                    role_id="55414"
                    nopeg_status="0"
                    v-model:value="state.params.useram"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_DISTRIBUTOR])"
                :xl="6" :md="8" :sm="12">
                <FilterSalesman
                    style="width:100%;"
                    mode="multiple"
                    v-model:value="state.params.salesman"
                    v-model:useram_id="state.params.useram"
                    v-model:vendor_id="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
                <ASkeleton
                    active
                    :paragraph="{ rows: 2 }"
                    :loading="state.isFetching">
                    <ADescriptions bordered size="small">
                        <ADescriptionsItem label="Date" :span="3">{{ state.labels.date }}</ADescriptionsItem>
                        <ADescriptionsItem label="Time Gone" :span="2">{{ state.labels.time_gone }}</ADescriptionsItem>
                        <ADescriptionsItem label="Days" :span="2">{{ state.labels.time_gone_day }}%</ADescriptionsItem>
                        <ADescriptionsItem label="Time Left" :span="2">{{ state.labels.time_left }}</ADescriptionsItem>
                        <ADescriptionsItem label="Days" :span="2">{{ state.labels.time_left_day }}%</ADescriptionsItem>
                    </ADescriptions>
                </ASkeleton>
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Performance-Monitoring-Board"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                <template #status="{ text }">
                    <ATag v-if="text" color="#108ee9">Sukses</ATag>
                    <ATag v-else color="grey">Gagal</ATag>
                </template>

                <template #workday_progress="{ text }">
                    <span :style="`color:${getColor(text)}`">{{ `${text}%` }}</span>
                </template>

                <template #visit_progress="{ text }">
                    <span :style="`color:${getColor(text)}`">{{ `${text}%` }}</span>
                </template>

                <template #coverage_progress="{ text }">
                    <span :style="`color:${getColor(text)}`">{{ `${text}%` }}</span>
                </template>

                <template #ca_progress="{ text }">
                    <span :style="`color:${getColor(text)}`">{{ `${text}%` }}</span>
                </template>

                <template #noo_progress="{ text }">
                    <span :style="`color:${getColor(text)}`">{{ `${text}%` }}</span>
                </template>

                <template #volume_progress="{ text }">
                    <span :style="`color:${getColor(text)}`">{{ `${text}%` }}</span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Detail">
                            <a
                                class="btn btn-sm btn-success"
                                size="small"
                                @click="btnDetail(record)">
                                <i class="fe fe-info fa-lg" />&nbsp;Detail
                            </a>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- detail -->
        <Detail
            v-if="visibleDetailModal"
            v-model:visible="visibleDetailModal"
            v-model:item="visibleDetailItemModal"/>
    
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterSalesman from '@/components/filter/FilterSalesman'
import FilterATSalesmanKabupatenByToko from '@/components/filter/FilterATSalesmanKabupatenByToko'
import FilterUsers from '@/components/filter/FilterUsers'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Detail from './Detail'
import localStorage from 'store'
import { pickBy, includes } from 'lodash'
import moment from 'moment'
import {
    hasRoles,
    ROLE_TSO,
    ROLE_ADMIN_BK,
    ROLE_DISTRIBUTOR,
    ROLE_TIM_CRM,
    ROLE_SALESMAN_DISTRIBUTOR,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterDistributor,
        FilterSalesman,
        FilterATSalesmanKabupatenByToko,
        FilterUsers,
        DownloadExcel,
        Detail,
    },
    setup() {
        const errorMessage = ref()
        const user = computed(() => localStorage.get('profile'))
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Salesman',
                    dataIndex: 'salesman_name',
                },
                {
                    title: 'Hari Kerja',
                    children: [
                        {
                            title: 'Target',
                            dataIndex: 'workdays_target',
                            align: 'center',
                        },
                        {
                            title: 'Actual',
                            dataIndex: 'workdays_actual',
                            align: 'center',
                        },
                        {
                            title: '%',
                            dataIndex: 'workdays_percentage',
                            align: 'center',
                            slots: { customRender: 'workday_progress' },
                        },
                    ],
                },
                {
                    title: 'Visit',
                    children: [
                        {
                            title: 'Target',
                            dataIndex: 'visits_target',
                            align: 'center',
                        },
                        {
                            title: 'Actual',
                            dataIndex: 'visits_actual',
                            align: 'center',
                        },
                        {
                            title: '%',
                            dataIndex: 'visits_percentage',
                            align: 'center',
                            slots: { customRender: 'visit_progress' },
                        },
                    ],
                },
                {
                    title: 'Coverage',
                    children: [
                        {
                            title: 'Target',
                            dataIndex: 'coverages_target',
                            align: 'center',
                        },
                        {
                            title: 'Actual',
                            dataIndex: 'coverages_actual',
                            align: 'center',
                        },
                        {
                            title: '%',
                            dataIndex: 'coverages_percentage',
                            align: 'center',
                            slots: { customRender: 'coverage_progress' },
                        },
                    ],
                },
                {
                    title: 'Customer Active',
                    children: [
                        {
                            title: 'Target',
                            dataIndex: 'ca_target',
                            align: 'center',
                        },
                        {
                            title: 'Actual',
                            dataIndex: 'ca_actual',
                            align: 'center',
                        },
                        {
                            title: '%',
                            dataIndex: 'ca_percentage',
                            align: 'center',
                            slots: { customRender: 'ca_progress' },
                        },
                    ],
                },
                {
                    title: 'NOO',
                    children: [
                        {
                            title: 'Target',
                            dataIndex: 'noo_target',
                            align: 'center',
                        },
                        {
                            title: 'Actual',
                            dataIndex: 'noo_actual',
                            align: 'center',
                        },
                        {
                            title: '%',
                            dataIndex: 'noo_percentage',
                            align: 'center',
                            slots: { customRender: 'noo_progress' },
                        },
                    ],
                },
                {
                    title: 'Volume',
                    children: [
                        {
                            title: 'Target',
                            dataIndex: 'vol_target',
                            align: 'center',
                        },
                        {
                            title: 'Actual',
                            dataIndex: 'vol_actual',
                            align: 'center',
                        },
                        {
                            title: '%',
                            dataIndex: 'vol_percentage',
                            align: 'center',
                            slots: { customRender: 'volume_progress' },
                        },
                    ],
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: '/api/report-ats/data-pmb',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isSync: false,
            params: {
                q: '',
                kabupaten: [],
                distributor: [],
                useram: [],
                salesman: [],
                date: moment().format('YYYY-MM-DD'),
                page: 1,
                "per-page": 10,
            },
            sync: {
                isShow: false,
                success: 0,
                failed: 0,
                data: [],
            },
            labels: {
                date: '',
                time_gone: '',
                time_left: '',
                time_gone_day: '',
                time_left_day: '',
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.date) {
                state.params.date = moment(state.params.date).endOf("month").format('YYYY-MM-DD')
            }

            return pickBy(params)
        }

        const resetLabelTime = () => {
            state.labels = {
                date: '',
                time_gone: '',
                time_left: '',
                time_gone_day: '',
                time_left_day: '',
            }
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            // reset table
            resetLabelTime()
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    
                    items.forEach((item) => {
                        state.labels.time_gone = state.labels.time_gone || item.time_gone_realization
                        state.labels.time_gone_day = state.labels.time_gone_day || item.time_gone_percentage
                        state.labels.time_left = state.labels.time_left || item.time_left_realization
                        state.labels.time_left_day = state.labels.time_left_day || item.time_left_percentage
                        state.labels.date = state.labels.date || item.date
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const getColor = (val) => {
            const { time_gone_day } = state.labels

            return val == 0 
                ? '' 
                : val == time_gone_day 
                ? '#2196f3' 
                : val > time_gone_day 
                ? '#4BAB00' 
                : '#B31E3C'
        }

        // handle detail
        const visibleDetailModal = ref(false);
        const visibleDetailItemModal = ref(null);

        const btnDetail = (item = null) => {
            visibleDetailModal.value = true
            visibleDetailItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            if (hasRoles([ROLE_TSO])) {
                state.params.useram = [user.value.id]
            }

            if (hasRoles([ROLE_DISTRIBUTOR])) {
                state.params.distributor = [user.value.vendor_id]
            }

            if (hasRoles([ROLE_SALESMAN_DISTRIBUTOR])) {
                state.params.salesman = [user.value.id]
            }

            // delete column
            // if (hasRoles([ROLE_TIM_CRM])) {
            //     state.columns = state.columns.filter(item => {
            //         if (!includes(['action'], item.dataIndex)) {
            //             return item
            //         }
            //     })
            // }
        })

        return {
            visibleDetailModal,
            visibleDetailItemModal,
            btnDetail,
            fetchDataList,
            state,
            getColor,
            // rowSelection,
            handleTableChange,
            errorMessage,
            hasRoles,
            ROLE_TSO,
            ROLE_ADMIN_BK,
            ROLE_DISTRIBUTOR,
            ROLE_TIM_CRM,
            ROLE_SALESMAN_DISTRIBUTOR,
        }
    },
})
</script>
